import React, { useCallback, useState, useEffect } from "react";
import { Box, Typography, IconButton, Slider, useTheme } from "@mui/material";
import { PlayArrow, Pause } from "@mui/icons-material";
import { usePlayerContext } from "./PlayerContext";
import IconUrls from "./IconUrls";
import { ExtraSettings } from "./types/ExtraSettings";

const Player: React.FC<Props> = (props) => {
  const { extraSettings } = props;

  const {
    show,
    currentEpisode,
    currentEpisodeTime,
    isCurrentEpisodePlaying,
    play,
    pause,
    seek,
  } = usePlayerContext();

  const theme = useTheme();

  const [isSliding, setIsSliding] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);

  const [episodeDuration, setEpisodeDuration] = useState(0);
  useEffect(() => {
    setEpisodeDuration(currentEpisode?.duration || 0);
  }, [currentEpisode]);

  const togglePlay = useCallback(() => {
    if (currentEpisode && !isCurrentEpisodePlaying) {
      play(currentEpisode);
    } else {
      pause();
    }
  }, [currentEpisode, isCurrentEpisodePlaying, play, pause]);

  const sliderOnChange = useCallback(
    (event: Event, value: number | number[], activeThumb: number) => {
      setIsSliding(true);
      setSliderValue(value as number);
    },
    [setIsSliding, setSliderValue],
  );

  const sliderOnChangeCommited = useCallback(
    (event: React.SyntheticEvent | Event, value: number | number[]) => {
      setIsSliding(false);
      setSliderValue(value as number);
      seek(convertPercentToSeconds(value as number, episodeDuration));
    },
    [episodeDuration, setIsSliding, setSliderValue, seek],
  );

  useEffect(() => {
    if (!isSliding) {
      const sliderPosition = formatTimeAsPercent(
        currentEpisodeTime,
        episodeDuration,
      );
      setSliderValue(sliderPosition);
    }
  }, [isSliding, sliderValue, currentEpisodeTime, episodeDuration]);

  const currentTimeDisplayed = !isSliding
    ? formatSecondsAsTime(currentEpisodeTime)
    : formatPercentAsTime(sliderValue, episodeDuration);

  const episodeDurationDisplayed = formatSecondsAsTime(episodeDuration);

  const rgb = hexToRgb(theme.palette.background.player);
  const lum = luminance(rgb[0], rgb[1], rgb[2]);
  const railColor = lum < 0.228 ? "#FFFFFF" : "#000000";

  const imageUrl = currentEpisode?.image || show.image;

  return (
    <Box
      sx={{
        width: "100%",
        height: "170px",
        padding: "12px",
        display: "flex",
        gap: 2,
        backgroundColor: "background.player",
      }}
    >
      <img src={imageUrl} width="124px" height="124px" />
      <Box
        sx={{
          width: "100%",
          padding: "0px 12px 0px 12px",
          display: "flex",
          gap: "12px",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "grid",
            gap: "8px",
          }}
        >
          <Typography
            color="text.secondary"
            textTransform="uppercase"
            fontWeight={700}
            fontSize="12px"
          >
            {show.title}
          </Typography>
          <Typography
            color="text.primary"
            fontWeight={700}
            fontSize="18px"
            lineHeight="18px"
            maxHeight="36px"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {currentEpisode?.title || "No episodes found"}
          </Typography>
          <Typography color="text.secondary" fontWeight={400} fontSize="12px">
            {`${currentTimeDisplayed} / ${episodeDurationDisplayed}`}
          </Typography>
          <Slider
            value={sliderValue}
            onChange={sliderOnChange}
            onChangeCommitted={sliderOnChangeCommited}
            sx={{
              "& .MuiSlider-track": {
                height: 3,
                color: "text.icon",
              },
              "& .MuiSlider-rail": {
                height: 3,
                color: railColor,
              },
              "& .MuiSlider-thumb": {
                height: 16,
                width: 16,
                color: "text.icon",
                borderWidth: "4px",
                borderStyle: "solid",
                borderColor: "background.button",
                "&:hover": {
                  boxShadow: "none",
                },
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
              },
              padding: "6px 0",
            }}
          />
          <IconUrls
            hideRssLink={extraSettings.hideRssLink}
            hideDownloadLink={extraSettings.hideDownloadLink}
          />
        </Box>
        <Box
          sx={{
            alignSelf: "stretch",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            sx={{
              backgroundColor: "background.button",
              "&:hover": {
                backgroundColor: "background.button",
                transform: "scale(1.1)",
              },
              boxShadow: "0 0 48px rgb(0 0 0 / 30%)",
            }}
            onClick={togglePlay}
          >
            {!isCurrentEpisodePlaying ? (
              <PlayArrow sx={{ fontSize: "36px", color: "text.icon" }} />
            ) : (
              <Pause sx={{ fontSize: "36px", color: "text.icon" }} />
            )}
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

interface Props {
  extraSettings: ExtraSettings;
}

const formatSecondsAsTime = (secs: number) => {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs - hours * 3600) / 60);
  const seconds = Math.floor(secs - hours * 3600 - minutes * 60);

  const displayedHours = `${hours}`.padStart(2, "0");
  const displayedMinutes = `${minutes}`.padStart(2, "0");
  const displayedSeconds = `${seconds}`.padStart(2, "0");

  return `${displayedHours}:${displayedMinutes}:${displayedSeconds}`;
};

const formatPercentAsTime = (percent: number, totalSeconds: number) => {
  const seconds = convertPercentToSeconds(percent, totalSeconds);
  return formatSecondsAsTime(seconds);
};

const formatTimeAsPercent = (seconds: number, totalSeconds: number) => {
  if (!totalSeconds) {
    return 0;
  }

  const percent = (seconds * 100) / totalSeconds;
  return Math.floor(percent);
};

const convertPercentToSeconds = (percent: number, totalSeconds: number) => {
  if (!totalSeconds) {
    return 0;
  }
  return (percent * totalSeconds) / 100;
};

/**
 *     100 ... totalSeconds
 * percent ... seconds
 */

const hexToRgb = (hex: string) => {
  // Original snippet that TS complains about
  // hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => `#${r + r + g + g + b + b}`)
  //   .substring(1)
  //   .match(/.{2}/g)
  //   .map(x => parseInt(x, 16))

  const components = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r + r + g + g + b + b}`,
    )
    .substring(1)
    .match(/.{2}/g) || ["FF", "FF", "ff"];

  return components.map((x) => parseInt(x, 16));
};

// Determine relation of luminance in color
const luminance = (r: number, g: number, b: number) => {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2;
  });

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

export default Player;
