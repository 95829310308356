import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Theme, CircularProgress } from "@mui/material";
import Parser from "rss-parser";
import Show from "./types/Show";
import Episode from "./types/Episode";
import Player from "./Player";
import EpisodeList from "./EpisodeList";
import { PlayerContext } from "./PlayerContext";
import ShowUrls from "./types/ShowUrls";
import { ExtraSettings } from "./types/ExtraSettings";

interface CustomFeed {
  "podcaster:facebook": string;
  "podcaster:twitter": string;
  "podcaster:instagram": string;
  "podcaster:tiktok": string;
}

const FEED_URL =
  "https://feed.cdnstream1.com/zjb/feed/download/e4/8d/68/e48d6814-bb22-4ac3-b8c0-041b20088d7f.xml";

const EmbeddedPlayer: React.FC<Props> = (props) => {
  const [show, setShow] = useState<Show | null>(null);
  const [showUrls, setShowUrls] = useState<ShowUrls | null>(null);
  const [episodes, setEpisodes] = useState<Episode[]>([]);
  const [extraSettings, setExtraSettings] = useState<ExtraSettings>({
    hideRssLink: false,
    hideDownloadLink: false,
  });
  const [currentEpisode, setCurrentEpisode] = useState<Episode | null>(null);
  const [currentEpisodeTime, setCurrentEpisodeTime] = useState(0);
  const [isCurrentEpisodePlaying, setIsCurrentEpisodePlaying] = useState(false);

  const audio = useRef(new Audio());

  useEffect(() => {
    const parser = new Parser<CustomFeed, any>({
      customFields: {
        feed: [
          "podcaster:facebook",
          "podcaster:twitter",
          "podcaster:instagram",
          "podcaster:tiktok",
        ],
      },
    });
    (async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const feedUrl = readFeedUrl(searchParams);
      const feed = await parser.parseURL(feedUrl);

      const show: Show = {
        title: feed.title || "Untitled Show",
        description: feed.description || "",
        image: feed.image?.url || "",
      };
      setShow(show);

      const episodes: Episode[] = feed.items.map((episode, i) => ({
        title: episode.title || "",
        description: episode.contentSnippet || "",
        number: i,
        image: episode.itunes.image,
        duration: +episode.itunes.duration,
        listenUrl: episode.enclosure?.url || "",
        publishDate: episode.isoDate,
      }));
      setEpisodes(episodes);

      if (episodes.length > 0) {
        setCurrentEpisode(episodes[0]);
      }

      setShowUrls({
        feed: feedUrl,
        facebook: feed["podcaster:facebook"] || null,
        twitter: feed["podcaster:twitter"] || null,
        instagram: feed["podcaster:instagram"] || null,
        tiktok: feed["podcaster:tiktok"] || null,
      });

      const extraSettings = readExtraSettings(searchParams);
      setExtraSettings(extraSettings);
    })();
  }, []);

  const play = useCallback(
    (episode: Episode) => {
      const src = `${episode.listenUrl}?aw_0_1st.playerId=Podcaster-ShowEmbed`;
      if (audio.current.src !== src) {
        audio.current.pause();
        audio.current.src = src;
      }
      audio.current.play();
      setCurrentEpisode(episode);
    },
    [audio, setCurrentEpisode],
  );

  const pause = useCallback(() => {
    audio.current.pause();
  }, [audio]);

  const seek = useCallback(
    (seconds: number) => {
      audio.current.currentTime = seconds;
      // Anticipate the jump to avoid race conditions
      setCurrentEpisodeTime(seconds);
    },
    [audio],
  );

  const onAudioEnded = useCallback(() => {
    if (!currentEpisode) {
      return;
    }
    // Episode numbers start at 0
    const hasNext = currentEpisode.number < episodes.length;
    if (!hasNext) {
      return;
    }

    const next = episodes[currentEpisode.number + 1];
    play(next);
  }, [currentEpisode, episodes, play]);

  useEffect(() => {
    audio.current.onplay = () => setIsCurrentEpisodePlaying(true);
    audio.current.onpause = () => setIsCurrentEpisodePlaying(false);
    audio.current.ontimeupdate = () =>
      setCurrentEpisodeTime(audio.current.currentTime);

    audio.current.onended = () => onAudioEnded();
  }, [audio, setCurrentEpisodeTime, setIsCurrentEpisodePlaying, onAudioEnded]);

  return (
    <Box
      sx={(theme: Theme) => ({
        minWidth: theme.breakpoints.values.sm,
        minHeight: theme.breakpoints.values.sm,
      })}
    >
      {show && showUrls ? (
        <PlayerContext.Provider
          value={{
            show,
            showUrls,
            episodes,
            currentEpisode,
            currentEpisodeTime,
            isCurrentEpisodePlaying,
            play,
            pause,
            seek,
          }}
        >
          <Box height="100vh" position="relative" overflow="hidden">
            <Box
              sx={{
                position: "absolute",
                top: "0px",
                borderBottom: "2px solid #e1e1e1",
                width: "100%",
              }}
            >
              <Player extraSettings={extraSettings} />
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "172px",
                overflow: "auto",
                height: "calc(100vh - 172px)",
                width: "100%",
              }}
            >
              <EpisodeList episodes={episodes} />
            </Box>
          </Box>
        </PlayerContext.Provider>
      ) : (
        <Box
          sx={(theme) => ({
            width: "100%",
            height: theme.breakpoints.values.sm,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          })}
        >
          <CircularProgress sx={{ color: "text.primary" }} />
        </Box>
      )}
    </Box>
  );
};

interface Props {}

const readFeedUrl = (params: URLSearchParams) => {
  const feedBase64 = params.get("feed");
  const feed = feedBase64 ? window.atob(feedBase64) : FEED_URL;
  return feed;
};

const readExtraSettings = (params: URLSearchParams): ExtraSettings => {
  return {
    hideRssLink: params.get("rss") === "0",
    hideDownloadLink: params.get("dl") === "0",
  };
};

export default EmbeddedPlayer;
