import { createContext, useContext } from "react";
import Show from "./types/Show";
import Episode from "./types/Episode";
import ShowUrls from "./types/ShowUrls";

interface PlayerContextData {
  show: Show;
  showUrls: ShowUrls;
  episodes: Episode[];
  currentEpisode: Episode | null;
  currentEpisodeTime: number;
  isCurrentEpisodePlaying: boolean;
  play: (episode: Episode) => void;
  pause: () => void;
  seek: (seconds: number) => void;
}

export const PlayerContext = createContext<PlayerContextData | null>(null);

export const usePlayerContext = () => {
  const contextValue = useContext(PlayerContext);
  if (!contextValue) {
    throw new Error("There's something wrong with our context.");
  }
  return contextValue;
};
