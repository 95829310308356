import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import Episode from "./types/Episode";
import EpisodeItem from "./EpisodeItem";

const initialEpisodeCount = +(
  new URLSearchParams(window.location.search).get("limit") || 10
);

const EpisodeList: React.FC<Props> = (props) => {
  const { episodes } = props;

  const [hasExpanded, setHasExpanded] = useState(false);

  const visibleEpisodes = hasExpanded
    ? episodes
    : episodes.slice(0, initialEpisodeCount);

  const canLoadMore = !hasExpanded && episodes.length > visibleEpisodes.length;

  return (
    <Box
      sx={{
        display: "grid",
        gap: "2px",
        backgroundColor: "background.player",
        marginX: "12px",
        marginBottom: 1,
      }}
    >
      {visibleEpisodes.map((episode: Episode) => (
        <EpisodeItem key={episode.number} episode={episode} />
      ))}
      {canLoadMore && (
        <Box width="100%" padding="2" display="flex" justifyContent="center">
          <Button
            fullWidth
            onClick={() => setHasExpanded(true)}
            sx={{
              color: "text.primary",
              backgroundColor: "background.player",
              opacity: "0.6",
              "&:hover": {
                backgroundColor: "background.player",
              },
            }}
          >
            Load All
          </Button>
        </Box>
      )}
    </Box>
  );
};

interface Props {
  episodes: Episode[];
}

export default EpisodeList;
