import React from "react";
import { Box, Link } from "@mui/material";
import { usePlayerContext } from "./PlayerContext";
import {
  Download,
  RssFeed,
  FacebookOutlined,
  Twitter,
  Instagram,
} from "@mui/icons-material";
import TikTok from "./icons/TikTok";

const IconUrls: React.FC<Props> = (props) => {
  const { hideRssLink, hideDownloadLink } = props;
  const { showUrls, currentEpisode } = usePlayerContext();

  return (
    <Box
      fontSize="16px"
      display="grid"
      gridAutoFlow="column"
      gap="4px"
      width="fit-content"
      color="text.icon"
    >
      {currentEpisode?.listenUrl && !hideDownloadLink && (
        <Link
          target="_blank"
          href={currentEpisode.listenUrl}
          download={currentEpisode.title}
          color="inherit"
        >
          <Download fontSize="inherit" color="inherit" />
        </Link>
      )}
      {!hideRssLink && (
        <Link href={showUrls.feed} target="_blank" color="inherit">
          <RssFeed fontSize="inherit" color="inherit" />
        </Link>
      )}
      {showUrls.facebook && (
        <Link href={showUrls.facebook} target="_blank" color="inherit">
          <FacebookOutlined fontSize="inherit" color="inherit" />
        </Link>
      )}
      {showUrls.twitter && (
        <Link href={showUrls.twitter} target="_blank" color="inherit">
          <Twitter fontSize="inherit" color="inherit" />
        </Link>
      )}
      {showUrls.instagram && (
        <Link href={showUrls.instagram} target="_blank" color="inherit">
          <Instagram fontSize="inherit" color="inherit" />
        </Link>
      )}
      {showUrls.tiktok && (
        <Link href={showUrls.tiktok} target="_blank" color="inherit">
          <TikTok fontSize="inherit" color="inherit" />
        </Link>
      )}
    </Box>
  );
};

interface Props {
  hideRssLink: boolean;
  hideDownloadLink: boolean;
}

export default IconUrls;
