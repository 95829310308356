import { useState } from "react";
import {
  CssBaseline,
  ThemeProvider,
  createTheme,
  StyledEngineProvider,
} from "@mui/material";
import EmbeddedPlayer from "./EmbeddedPlayer";

declare module "@mui/material/styles/createPalette" {
  interface TypeBackground {
    player: string;
    button: string;
  }

  interface TypeText {
    icon: string;
  }
}

const readColors = (params: URLSearchParams) => ({
  playerBackgroundColor: `#${params.get("pbg") ?? "EFEFEF"}`,
  listBackgroundColor: `#${params.get("lbg") ?? "F9F9F9"}`,
  primaryTextColor: `#${params.get("pt") ?? "353D58"}`,
  secondaryTextColor: `#${params.get("st") ?? "676E85"}`, // podcast title and timer
  buttonBackgroundColor: `#${params.get("bbg") ?? "FFFFFF"}`,
  buttonIconColor: `#${params.get("bi") ?? "353D58"}`,
});

function App() {
  const [theme] = useState(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const colors = readColors(searchParams);

    return createTheme({
      palette: {
        background: {
          default: colors.listBackgroundColor,
          player: colors.playerBackgroundColor,
          button: colors.buttonBackgroundColor,
        },
        text: {
          primary: colors.primaryTextColor,
          secondary: colors.secondaryTextColor,
          icon: colors.buttonIconColor,
        },
      },
      components: {
        MuiSvgIcon: {
          defaultProps: {
            color: "action",
          },
          styleOverrides: {
            colorAction: colors.buttonIconColor,
          },
        },
        MuiLink: {
          styleOverrides: {
            root: {
              textDecoration: "none",
            },
          },
        },
        MuiSlider: {
          styleOverrides: {
            root: {
              "@media (pointer:coarse)": {
                padding: "inherit",
              },
            },
          },
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 300,
          md: 600,
          lg: 900,
          xl: 1200,
        },
      },
    });
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <EmbeddedPlayer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
