import React, { useState, useCallback } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { PlayArrow, Pause } from "@mui/icons-material";
import Episode from "./types/Episode";
import { usePlayerContext } from "./PlayerContext";

const EpisodeItem: React.FC<Props> = (props) => {
  const { episode } = props;
  const { currentEpisode, isCurrentEpisodePlaying, play, pause } =
    usePlayerContext();

  const isPlaying = useCallback(() => {
    return currentEpisode?.number === episode.number && isCurrentEpisodePlaying;
  }, [episode, currentEpisode, isCurrentEpisodePlaying]);

  const playThisEpisode = useCallback(() => play(episode), [episode, play]);

  const togglePlay = useCallback(() => {
    if (!isPlaying()) {
      playThisEpisode();
    } else {
      pause();
    }
  }, [isPlaying, playThisEpisode, pause]);

  return (
    <Box
      sx={{
        display: "flex",
        paddingY: "12px",
        gap: "12px",
        backgroundColor: "background.default",
        position: "relative",
        width: "100%",
      }}
    >
      <Box width="40px" height="40px">
        <IconButton
          sx={{
            backgroundColor: "background.button",
            color: "text.icon",
            "&:hover": {
              backgroundColor: "background.button",
              transform: "scale(1.1)",
            },
            // borderRadius: "50",
            boxShadow: "0 0 40px rgb(0 0 0 / 30%)",
          }}
          onClick={togglePlay}
        >
          {!isPlaying() ? (
            <PlayArrow color="inherit" />
          ) : (
            <Pause color="inherit" />
          )}
        </IconButton>
      </Box>
      <Box display="grid" flexGrow={1}>
        <Typography
          fontWeight={700}
          fontSize="14px"
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {episode.title}
        </Typography>
        <Typography
          color="text.secondary"
          fontWeight={400}
          fontSize="14px"
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {episode.description}
        </Typography>
      </Box>
      <Box
        width="80px"
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="flex-end"
      >
        <Typography
          color="text.secondary"
          fontWeight={400}
          fontSize="12px"
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {formatSecondsAsTime(episode.duration)}
        </Typography>
        <Typography
          color="text.secondary"
          fontWeight={400}
          fontSize="12px"
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {new Date(episode.publishDate).toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })}
        </Typography>
      </Box>
    </Box>
  );
};

interface Props {
  episode: Episode;
}

const formatSecondsAsTime = (secs: number) => {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor((secs - hours * 3600) / 60);
  const seconds = Math.floor(secs - hours * 3600 - minutes * 60);

  const displayedHours = `${hours}`.padStart(2, "0");
  const displayedMinutes = `${minutes}`.padStart(2, "0");
  const displayedSeconds = `${seconds}`.padStart(2, "0");

  return displayedHours !== "00"
    ? `${displayedHours}:${displayedMinutes}:${displayedSeconds}`
    : `${displayedMinutes}:${displayedSeconds}`;
};

export default EpisodeItem;
